
:root {
  --dark-bg: #000;
  --light-bg: #f6f7fb;
  --warm-bg: #FFF8DC;
  --primary-color: #00205B;
  --secondary-color: #ED1B24;
  --third-color: #FFD200;
  --fourth-color: #FFFFFF;
  --text-color: #00205B;
  --text-secondary: #FFD200;
  --text-color-dark: #12183f;
  --text-color-light: #fcf3d4;
  --title-color: #00205B;;
  --border-color: #ED1B24;
  --border-color-dark: #27263a;
  --border-color-light: #6a55f8;
  --shadow: 10px 12px 48px rgba(181, 187, 203, 0.206);
  --font-family: 'Montserrat', sans-serif;
}

html {
  box-sizing: border-box;
}

body {
  color: var(--text-color);
  margin: 0 !important;
  max-width: 100%;
  overflow-x: hidden;
  padding: 0 !important;
  touch-action: pan-y, pan-x;
  transition: all 0.4s cubic-bezier(0.57, 0.25, 0.33, 1) 0s;
}

.container {
  width: 100%;
  margin: auto;
  overflow: hidden;
  
}

.outline-icon {
  fill: none;
  stroke: currentColor;
  stroke-width: 5;
  color: var(--secondary-color);
}

@media (min-width: 2100px) {
  .container {
    max-width: 1600px;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}