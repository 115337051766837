
.f-container .nav-link {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    text-decoration: none;
    background: none;
    border: none;
}

// Legacy
.f-wrapper .container {
    padding-top: 5rem;
    padding-bottom: 2rem;
}

.f-wrapper {
    background-color: var(--primary-color);
}

.f-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.f-container .title {
    color: white;
}

.f-container .emailBox {
    max-width: 36rem;
}

.f-container hr {
    border-color: var(--border-color-light);
    width: 100%;
}

.f-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
}

.f-menu span, .nav-link > a {
    color: white;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.f-menu .highlight {
    color: var(--secondary-color);
}

.h-description {
    color: white;
    font-size: 1.4rem;
    line-height: 2rem;
}

@media (max-width: 990px) {
    .f-menu {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    }
}


