body {
    font-family: 'Montserrat', sans-serif;
  }

  .h-list {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow:auto;
      }
      li {
        float: left;
      }
      
      li a {
        display: block;
        text-align: center;
        padding: 16px;
        text-decoration: none;
      }
    }

    .h-scrollable {
      overflow-x: auto; /* Enable horizontal scrolling */
      scrollbar-width: none; /* Hide scrollbar for Firefox */
      -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    }
    
    .h-scrollable::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
    }