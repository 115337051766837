.n-menu .highlight, nm-menu .highlight {
    color: var(--secondary-color);
}

.n-wrapper {
    background-color: var(--primary-color);
    width: 100%;
    position: absolute;
    z-index: 999;
}

.sticky {
    position: fixed;
    box-shadow: 5px 6px 24px #ED1B24;;
    background: white;
    top: 0;  
    
    .logo {
        height: 50px;
    }
}

.n-container .nav-link button, .n-container .nav-link {
    padding: 1.5rem 1rem;
}

.n-container .nav-link button, .n-container .nav-link, .nm-container .nav-link button, .nm-container .nav-link {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    text-decoration: none;
    background: none;
    border: none;
}

.sticky .n-container .nav-link button, .sticky .n-container .nav-link {
    color: var(--title-color);
    padding: 1rem;
}

.n-logo {
    font-weight: 500;
    cursor: pointer;
    float:left;
    padding-top: 0.6rem;
}

.n-right {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    font-size: 1.2rem;
    float:right;
}

.n-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
}

.n-menu span {
    cursor: pointer;
}

.fund-button {
    color: var(--dark-bg);
    background-color: var(--fourth-color);
    padding: 1rem 1.6rem;
    font-size: 1rem;
    text-transform: capitalize;
    border-radius: 999px;
}

.nm-container {
    display: none;
}

.logo {
    height: 0px;
}

.nm-menu .highlight {
    color: var(--secondary-color);
}

.mobile-menu {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
    display: block;
}
@media (max-width: 1200px) {
    .n-container {
        display: none;
    }
    .nm-container {
        display: block;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        position: relative;
        background-color: var(--primary-color);
    }
    .nm-container>:nth-child(1) {
        font-weight: bold;
    }
    .nm-menu {
        position: fixed;
        top: 3.8rem;
        padding: 3rem 2rem;
        box-sizing: border-box;
        left: 0;
        display: flex;
        background-color: var(--primary-color);
        flex-direction: column;
        transform: translateX(-150%);
        transition: all 300ms ease-in-out;
        width: 100%;
        font-size: 1.2rem;
        z-index: 99;
        height: calc(100vh - 3.8rem);
    }

    .nm-menu span, .nm-menu a span {
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--border-color);
    }

    .m-funded-button {
        width: 100%;
        background-color: var(--third-color);
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 1rem 0rem;
        border-radius: 999px;
        color: var(--dark-bg);
    }
}

